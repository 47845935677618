<template>
  <div>
    <div class="header-title">{{ $route.meta.title }}</div>
    <div class="">
      <div>
        <div class="btn-con" style="float: left">
          <button
            type="button"
            class="next-btn next-medium next-btn-primary"
            style="margin-right: 8px"
            @click="adduser"
          >
            <span class="next-btn-helper">创建用户</span>
          </button>
          <importExport
            :type="1"
            btn-name="用户导入"
            @upload="get_list"
          />
        </div>
        <el-input
          v-model="page.search"
          prefix-icon="el-icon-search"
          size="small"
          style="width: 300px"
          placeholder="输入主用户的登录名/显示名/邮箱/手机号"
          clearable
          @keyup.enter.native="get_list"
          @clear="get_list"
        />
        <div
          style="float: right"
          dir="ltr"
          role="gridcell"
          class="next-col next-col-offset-0 sc-ifAKCX ezKSUK action-bar-right"
        >
          <button
            type="button"
            class="next-btn next-medium next-btn-normal isOnlyIcon"
            @click="get_list"
          >
            <span class="next-btn-helper"><i class="el-icon-refresh-right" /></span>
          </button>
        </div>
      </div>
      <Loading :show="loading">
        <div class="list-box">
          <el-table
            :data="tableData"
            style="width: 100%"
            :header-cell-style="headerCallStyle"
            @selection-change="handleCheckedCitiesChange"
          >
            <el-table-column type="selection" align="center" width="55" />
            <el-table-column prop="date" label="用户登录名称/显示名称">
              <template slot-scope="{ row }">
                <button
                  type="button"
                  class="next-btn next-medium next-btn-primary next-btn-text"
                  style="margin-right: 8px"
                  @click="handle(row)"
                >
                  {{ row.name }}
                </button>
                <p v-if="row.display_name">{{ row.display_name }}</p>
              </template>
            </el-table-column>
            <el-table-column prop="domain_name" label="所属域" />
            <el-table-column prop="alias" label="别名" />
            <el-table-column prop="parent_domain_name" label="父级域" />
            <el-table-column prop="create_time" label="创建时间" />
            <el-table-column prop="update_time" label="最后登录时间" />
            <el-table-column label="操作">
              <template slot-scope="{row}">
                <!-- <button
                  type="button"
                  class="next-btn next-medium next-btn-primary next-btn-text"
                  style="margin-right: 8px"
                  @click="showDrawer"
                >
                  <span class="next-btn-helper">添加组成员</span>
                </button>
                <button
                  type="button"
                  class="next-btn next-medium next-btn-primary next-btn-text"
                  style="margin-right: 8px"
                  @click="showDrawer"
                >
                  <span class="next-btn-helper">添加角色</span>
                </button>
                <button
                  type="button"
                  class="next-btn next-medium next-btn-primary next-btn-text"
                  style="margin-right: 8px"
                  @click="showDrawer"
                >
                  <span class="next-btn-helper">添加权限</span>
                </button> -->
                <button
                  type="button"
                  class="next-btn next-medium next-btn-primary next-btn-text"
                  style="margin-right: 8px"
                  @click="delete_main(row)"
                >
                  <span class="next-btn-helper">删除</span>
                </button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div style="padding: 20px 10px 10px; font-size: 18px">
          <el-row>
            <el-col :span="12">
              <el-checkbox v-model="checkAll" :indeterminate="isIndeterminate" style="margin-left:10px">
                <!-- <button
                  :disabled="!disabledAll"
                  type="button"
                  class="next-btn next-medium next-btn-primary next-btn-text"
                  style="margin-right: 8px"
                >
                  添加到用户组
                </button>
                <button
                  :disabled="!disabledAll"
                  type="button"
                  class="next-btn next-medium next-btn-primary next-btn-text"
                  style="margin-right: 8px"
                >
                  添加角色
                </button>
                <button
                  :disabled="!disabledAll"
                  type="button"
                  class="next-btn next-medium next-btn-primary next-btn-text"
                  style="margin-right: 8px"
                >
                  添加权限
                </button> -->
                <!-- <button
                  :disabled="!disabledAll"
                  type="button"
                  class="next-btn next-medium next-btn-primary next-btn-text"
                  style="margin-right: 8px"
                >
                  删除
                </button> -->
                <button
                  :disabled="!disabledAll"
                  type="button"
                  class="next-btn next-medium next-btn-primary next-btn-text"
                  style="margin-right: 8px"
                  @click="exportExcel"
                >
                  用户导出
                </button>
              </el-checkbox>
            </el-col>
            <el-col :span="12">
              <div style="text-align: right">
                <el-pagination
                  :page-size="page.page_num"
                  :current-page="page.pid"
                  background
                  layout="total, prev, pager, next"
                  :total="total"
                  @current-change="handleCurrentChange"
                />
              </div>
            </el-col>
          </el-row>
        </div>
      </Loading>
    </div>
    <el-drawer
      ref="drawer"
      custom-class="drawer-warp"
      :size="700"
      title="添加到用户组"
      :visible.sync="drawer"
    >
      <div class="drawer__content">
        <el-alert
          title="用户加入到用户组后，将拥有该组所有权限。"
          type="warning"
          :closable="false"
          show-icon
        />

        <el-form
          ref="ruleForm"
          style="padding:0"
          label-position="top"
          :model="form"
          :rules="rules"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item label="用户" prop="name">
            <el-select
              v-model="value1"
              multiple
              placeholder="请选择"
              style="width: 100%"
            >
              <el-option
                v-for="item in selectList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
        </el-form>
        <Transfer :list="tableData" />

      </div>
      <div class="panel-footer-drawer custom-panel-footer">
        <div class="btn-con" style="float: left">
          <button
            type="button"
            class="next-btn next-medium next-btn-primary"
            style="margin-right: 8px"
          >
            <span class="next-btn-helper">确定</span>
          </button>
          <button
            type="button"
            class="next-btn next-medium next-btn-normal"
            style="margin-right: 8px"
            @click="$refs.drawer.closeDrawer()"
          >
            <span class="next-btn-helper">取消</span>
          </button>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import importExport from '@/components/Import'
import Transfer from '@/components/transfer'
import { main_user_list, delete_main_user, user_import_or_export } from '@/api'

export default {
  components: {
    importExport,
    Transfer
  },
  data () {
    return {
      ids: [],
      value1: '',
      drawer: false,
      input: '',
      tableData: [],
      total: 0,
      checkAll: false,
      isIndeterminate: true,
      disabledAll: false,
      loading: false,
      form: {},
      selectList: [],
      page: {
        search: '',
        pid: 1,
        page_num: 10
      },
      rules: {
        name: [
          { required: true, message: '请输入活动名称', trigger: 'blur' },
          { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ]
      }
    }
  },
  computed: {
    headerCallStyle () {
      return {
        background: '#eff3f8',
        color: '#333',
        fontWeight: 400,
        fontSize: '12px'
      }
    }
  },
  created () {
    this.get_list()
  },
  methods: {
    async exportExcel () {
      const params = {
        select_list: this.ids.join(','),
        export_account_type: 1
      }
      this.loading = true
      const response = await user_import_or_export(params)
      this.loading = false
      const that = this
      const blob = new Blob([response], { type: 'application/vnd.ms-excel' })
      if (response.type === 'application/json') {
        // 如果后端传的json数据则将blob转换成json（比如返回错误信息）
        const reader = new FileReader()
        reader.readAsText(blob, 'utf-8')
        reader.onload = function (e) {
          const result = JSON.parse(reader.result)
          if (result.res === 0) {
            that.$message.error(result.msg)
          }
        }
      } else {
        // 如果不是json数据就读取文件
        const getTime = new Date().getTime()
        const fileName = `user——${getTime}.xlsx`
        if ('download' in document.createElement('a')) { // 非IE下载
          const elink = document.createElement('a')
          elink.download = fileName
          elink.style.display = 'none'
          elink.href = URL.createObjectURL(blob)
          document.body.appendChild(elink)
          elink.click()
          URL.revokeObjectURL(elink.href) // 释放URL 对象
          document.body.removeChild(elink)
        } else { // IE10+下载
          navigator.msSaveBlob(blob, fileName)
        }
      }
    },
    get_list () {
      this.loading = true
      main_user_list(this.page).then(res => {
        this.loading = false
        this.tableData = res.data.data
        this.total = res.data.all_num
      }).catch(() => {
        this.loading = false
      })
    },
    delete_main (row) {
      const params = {
        user_id: row.id
      }
      delete_main_user(params).then(res => {
        this.get_list()
        this.$message({
          showClose: true,
          message: res.msg,
          type: 'success'
        })
      })
    },

    handleCheckedCitiesChange (list) {
      const checkedCount = list.length
      this.disabledAll = checkedCount
      this.checkAll = checkedCount === this.tableData.length
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.tableData.length
      this.ids = list.map(({ id }) => id)
    },
    showDrawer () {
      this.drawer = true
      this.selectList
    },
    handleCurrentChange (val) {
      this.page.pid = val
      this.get_list()
    },
    adduser () {
      this.$router.push('/main/add')
    },
    handle ({ id }) {
      this.$router.push({
        path: '/main/handle',
        query: { id }
      })
    }
  }
}
</script>

<style>
</style>
